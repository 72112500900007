// src/components/ProtectedRoute.tsx
import React, { ReactElement } from 'react';
import { Navigate } from 'react-router-dom';

interface ProtectedRouteProps {
    element: ReactElement;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ element }) => {
    const isAuthenticated = localStorage.getItem('authenticated') === 'true';

    if (!isAuthenticated) {
        console.log('not Authenticated from protectedroute');
        return <Navigate to="/login" replace />;
    } 
    
    return element;
};

export default ProtectedRoute;

