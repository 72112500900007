import React, { createContext, useContext, useState, ReactNode } from 'react';

interface LayoutContextType {
    sideBarOpen: boolean;
    handleSideBarOpen: () => void;
    handleSideBarClose: () => void;
}

const LayoutContext = createContext<LayoutContextType | undefined>(undefined);

export function useLayout(): LayoutContextType {
    const context = useContext(LayoutContext);
    if (!context) {
        throw new Error('useLayout must be used within a LayoutProvider');
    }
    return context;
}

interface LayoutProviderProps {
    children: ReactNode;
}

export function LayoutProvider({ children }: LayoutProviderProps) {
    const [sideBarOpen, setSideBarOpen] = useState(false);

    const handleSideBarOpen = () => setSideBarOpen(true);
    const handleSideBarClose = () => setSideBarOpen(false);

    return (
        <LayoutContext.Provider value= {{ sideBarOpen, handleSideBarOpen, handleSideBarClose }}>
            { children }
        </LayoutContext.Provider>
    );
}
