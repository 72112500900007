// src/components/Settings.tsx
import React from 'react';
import { SettingsContainer } from './style';

const Setting = () => {
    return (
        <SettingsContainer>
            <h1>Settings</h1>
            <p>Welcome to the Settings page</p>
        </SettingsContainer>
    )
};

export default Setting;