import React from 'react';
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText, useTheme, IconButton, Divider, useMediaQuery, Grid, Toolbar, Typography, Tooltip } from '@mui/material';
import { Link } from 'react-router-dom';
import { Dashboard as DashboardIcon, ChevronRight as ChevronRightIcon, ChevronLeft as ChevronLeftIcon, CloudCircle as CloudCircleIcon } from '@mui/icons-material';
import { DrawerFooter, DrawerHeader, StyledDrawer } from './style';
import { useLayout } from '../../context/BaseLayoutContext';

const isDevMode = process.env.REACT_APP_DEV_MODE === 'true';

// https://dev.to/ramonak/dashboard-layout-with-react-js-and-material-ui-27m4
// https://github.com/KaterinaLupacheva/dashboard-layout/blob/master/src/components/Drawer.tsx
// https://mui.com/material-ui/react-drawer/
const sidebar_menu_items = [
    {
        name: "Dashboard",
        icon: <DashboardIcon />,
        path: "/",
    },
    // {
    //     name: "Integration",
    //     icon: <CloudCircleIcon />,
    //     path: "/integration",
    // }

    // Conditionally include the Integration item
    // remove and enable the above after dev is done
    ...(isDevMode ? [{
        name: "Integration",
        icon: <CloudCircleIcon />,
        path: "/integration",
    }] : [])
];

const Sidebar = () => {
    const theme = useTheme()
    const { sideBarOpen, handleSideBarClose, handleSideBarOpen } = useLayout()
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'))
    
    const handleSideBar = () => {
        if (sideBarOpen) {
            handleSideBarClose()
        } else {
            handleSideBarOpen()
        }
    }

    return (
        <StyledDrawer
            variant={
                isLargeScreen ? "permanent" : "temporary"
            }
            open={sideBarOpen}
        >
            <DrawerHeader>
                <Typography
                    variant="h5"
                    noWrap
                    component="div"
                    sx={{
                        margin: 'auto',
                        backgroundImage: 'linear-gradient(to right, violet, pink, skyblue)',
                        WebkitBackgroundClip: 'text',
                        WebkitTextFillColor: 'transparent',
                        fontWeight: 'bold',
                        fontSize: sideBarOpen ? '1.5rem' : '1rem',
                        overflow: "visible",
                    }}
                >
                    Daicus
                </Typography>
            </DrawerHeader>
            <Grid>
                <List sx={{ p: 0 }}>
                    {sidebar_menu_items.map((item, index) => ( 
                        <Tooltip
                            key={item.name}
                            title={item.name}
                            placement="right"
                            arrow
                            disableHoverListener={sideBarOpen}
                        >
                            <ListItem
                                key={item.name}
                            >
                                <ListItemButton
                                    component={Link}
                                    to={item.path}
                                    sx={{
                                        minHeight: 48,
                                        justifyContent: sideBarOpen ? 'initial' : 'center',
                                        px: 2.5,
                                    }}
                                >
                                    <ListItemIcon
                                        sx={{
                                            minWidth: 0,
                                            mr: sideBarOpen ? 3 : 'auto',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        {item.icon}
                                    </ListItemIcon>
                                    <ListItemText primary={item.name} sx={{ opacity: sideBarOpen ? 1 : 0 }} />
                                </ListItemButton>
                            </ListItem>
                        </Tooltip>
                    ))}
                </List>
                <Divider />
                <DrawerFooter>
                    <IconButton onClick={handleSideBar}>
                        {sideBarOpen ? <ChevronLeftIcon />: <ChevronRightIcon />}
                    </IconButton>
                </DrawerFooter>
            </Grid>
        </StyledDrawer>
    );
};

export default Sidebar;
