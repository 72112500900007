import { axiosInstance } from "./AxiosInstance";

type Assignment = {
	prompt: string;
	assignedTo: string;
	data: {
		reference_data: Array<any>;
		target_data: Array<any>;
	};
	status: string;
	created_at: string;
	updated_at: string;
};

export const getAssignments = async () => {
	return await axiosInstance.get("/assignments/");
};

export const createAssignment = async (assignment: Assignment) => {
	return await axiosInstance.post("/assignments/", assignment);
};

export const getAssignment = async (assignment_id: string) => {
	return await axiosInstance.get(`/assignments/${assignment_id}`);
};
