import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { DashboardContainer } from './style';
import { Button, Typography, Box, useTheme, FormGroup, Select, FormControl, MenuItem, SelectChangeEvent, InputLabel, FormControlLabel, FormLabel } from "@mui/material";
import { Card, CardHeader, CardActions, CardContent, CardMedia } from '@mui/material';
import { KeyboardArrowDown as KeyboardArrowDownIcon } from '@mui/icons-material';
import { Navigate, useNavigate } from 'react-router-dom';
import { FormInputDropdown } from '../../components/Forms/FormInputDropdown';

const isDevMode = process.env.REACT_APP_DEV_MODE === 'true';

const dashboard_items = [
    {
        name: "Data Verification",
        description: "This is the data verification page",
        image: "https://source.unsplash.com/random",
        path: "/verification"
    },
    {
        name: "Data Visualization",
        description: "This is the data visualization page",
        image: "https://source.unsplash.com/random",
        path: "/visualization"
    },
]

const NewAssignmentCard = () => {
    const default_values = {
        selected_assignment_type: "Select Assignment Type"
    }
    const { handleSubmit, control, formState: { errors } } = useForm({
        defaultValues: default_values
    });

    const assignments = [
        {
            value: "Data Verification",
            label: "Data Verification",
            description: "Verify the reference data with the target data.",
            path: "/verification"
        },
    ]

    const navigate = useNavigate();

    const onSubmit = (data: any) => {
        if (data.selected_assignment_type === "Select Assignment Type") {
            return;
        }
        navigate(assignments.find(assignment => assignment.value === data.selected_assignment_type)?.path || "/")
    }

    return (
        <Card sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-between',
        }}>
            <CardHeader title="Create New Assignment" />
            <CardContent>
                <Box
                    component="form"
                    sx={{
                        display: 'flex',
                        // flexDirection: 'column',
                        gap: 2,
                    }}
                >
                    <FormLabel>Tell us the type of assignment</FormLabel>
                    <FormInputDropdown
                        name="selected_assignment_type"
                        label="Assignment Type"
                        control={control}
                        defaultValue=""
                        required
                        error={false}
                        options={assignments}
                    />
                </Box>
            </CardContent>
            <CardActions>
                <Button size="small" onClick={handleSubmit(onSubmit)}>Create Assignment</Button>
            </CardActions>
        </Card>
    )
}

const HistoryCard = () => {
    return (
        <Card>
            <CardHeader title="Assignment History" />
            <CardContent>
                <Typography variant="body2" color="text.secondary">
                    No history found.
                </Typography>
            </CardContent>
        </Card>
    )
}

const Dashboard = () => {
    const theme = useTheme();
    
    return (
        <DashboardContainer theme={theme}>
            {/* new assignment card */}
            <NewAssignmentCard />

            {/* history card */}
            {isDevMode && <HistoryCard />}
            
        </DashboardContainer>
    )
};

export default Dashboard;

// dashboard_items.map((item, index) => (
//                 <Card key={index}>
//                     <CardMedia
//                         component="img"
//                         height="140"
//                         image={item.image}
//                         alt={item.name}
//                     /> 
//                     <CardContent>
//                         <Typography gutterBottom variant="h5" component="div">
//                             {item.name}
//                         </Typography>
//                         <Typography variant="body2" color="text.secondary">
//                             {item.description}
//                         </Typography>
//                     </CardContent>
//                     <CardActions>
//                         <Button size="small" href={item.path}>Go to {item.name}</Button>
//                     </CardActions>
//                 </Card>
//             ))
//             