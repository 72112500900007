// src/components/Header/style.ts

// import styled from "@emotion/styled";

// export const HeaderWrapper = styled.div`
// 	top: 0;
// 	width: 100%;
// 	height: 60px;
// 	background-color: #f8f9fa;
// 	display: flex;
// 	justify-content: center;
// 	align-items: center;
// 	border-bottom: 1px solid #e7e7e7;
// 	z-index: 1000; // Ensures the header is above all other content
// 	p {
// 		margin: 0;
// 	}
// `;

// export const BrandLink = styled.a`
// 	font-size: 20px;
// 	font-weight: 600;
// 	color: #333;
// 	text-decoration: none;
// `;
import { styled } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';

const drawerWidth = 240;

interface AppBarProps {
	open: boolean;
}

// const AppBarStyled = styled(AppBar, {
// 	shouldForwardProp: (prop) => prop !== 'open',
// })<AppBarProps>(({ theme, open }) => ({
// 	zIndex: theme.zIndex.drawer + 1,
// 	transition: theme.transitions.create(['width', 'margin'], {
// 		easing: theme.transitions.easing.sharp,
// 		duration: theme.transitions.duration.leavingScreen,
// 	}),
// 	...(open && {
// 		marginLeft: drawerWidth,
// 		width: `calc(100% - ${drawerWidth}px)`,
// 		transition: theme.transitions.create(['width', 'margin'], {
// 			easing: theme.transitions.easing.sharp,
// 			duration: theme.transitions.duration.enteringScreen,
// 		}),
// 	}),
// }));

const StyledAppBar = styled(AppBar)(({ theme }) => ({
	position: "fixed",
	width: "100%",
	zIndex: theme.zIndex.drawer + 1,
	background: "transparent",
	boxShadow: 'none',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'flex-end',
}));


export default StyledAppBar;
