// a container component that handles the file upload and the file list view

import React, { useContext, useState } from 'react';
import { Box, Button, Grid, IconButton, Typography } from '@mui/material';
import { AddBoxOutlined } from '@mui/icons-material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const FileUpload = (
    {
        selectedFiles,
        setSelectedFiles,
    }: {
        selectedFiles: File[],
        setSelectedFiles: React.Dispatch<File[]>,
    }
) => {

    const handleAddFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (files) {
            setSelectedFiles([...selectedFiles, ...Array.from(files)]);
        }
    }

    const handleRemoveFiles = (fileToRemove: File) => {
        setSelectedFiles(selectedFiles.filter(file => file !== fileToRemove));
    }

    return (
        <Box>
            <IconButton
                component="label"
                aria-label="upload file"
                color="primary"
                size="large"
                sx={{
                    position: "absolute",
                }}
            >
                <AddBoxOutlined />
                <input
                    type="file"
                    multiple
                    hidden
                    accept=".csv, .xlsx, .xls, .pdf"
                    onChange={handleAddFiles}
                />
            </IconButton>
        </Box>
    )
}


export default FileUpload;