// src/App.tsx
import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import MainRoutes from './routes';
import './App.css';
import BaseLayout from './layouts/BaseLayout';
import Login from './views/Login/Login';

function App() {
    const isAuthenticated = localStorage.getItem('authenticated') === 'true';

    return (
        <Router>
            <Routes>
                <Route path="/login" element={<Login />} />
                <Route
                    path="/*"
                    element={
                        isAuthenticated ? (
                            <BaseLayout>
                                <MainRoutes />
                            </BaseLayout>
                        ) : (
                            <Navigate to="/login" replace />
                        )
                    }
                />
            </Routes>
        </Router>
    );
}

export default App;
