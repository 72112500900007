import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { Controller } from "react-hook-form";
import { FormInputProps } from "./FormInputProps";
import React from "react";

// https://blog.logrocket.com/using-material-ui-with-react-hook-form/
export const FormInputDropdown: React.FC<FormInputProps> = ({
    name,
    label,
    control,
    defaultValue,
    required,
    error,
    options
 }) => {
    return (
        <FormControl
            size="small"
            fullWidth
        >
            <Controller
                name={name}
                control={control}
                render={({ field }) => (
                    <Select
                        {...field}
                        displayEmpty
                        required={required}
                        error={error}
                        renderValue={(value) => {
                            if (value === "") {
                                return <em>{defaultValue}</em>
                            }
                            return value;
                        }}
                        inputProps={{ 'aria-label': 'Without label' }}
                    >
                        {options.map((option, index) => (
                            <MenuItem key={index} value={option.value}>{option.label}</MenuItem>
                        ))}
                    </Select>
                )}
            />
        </FormControl>
    )
}