import { Box, Theme } from "@mui/material";
//import styled from "@emotion/styled";
import { styled } from '@mui/material/styles';

export const IntegrationContainer = styled(Box, {
    shouldForwardProp: (prop) => prop !== "spacing",
})(({ theme }: { theme: Theme }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    gap: theme.spacing(2),
}));