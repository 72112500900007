import React, { useState } from 'react';
import { Button, Typography, Stepper, StepLabel, Step, Grid, Box, Snackbar, Alert } from '@mui/material';
import { DataVerificationContext, ErrorMessage, AuditResult } from '../../context/DataVerificationContext';
import { FileDetail } from '../../types/Files';
import QueryInput from './Pages/QueryInput';
import FileSelect from './Pages/FileSelect';
import ReviewPage from './Pages/ReviewPage';
import ResultPage from './Pages/ResultPage';
import axios from 'axios';
import { createAssignment } from '../../api/Assignments';

const isDevMode = process.env.REACT_APP_DEV_MODE === 'true';

const VerificationPage = () => {
    const [errors, setErrors] = useState<ErrorMessage[]>([]);
    
    const [selectedReferenceFiles, setSelectedReferenceFiles] = useState<FileDetail[]>([]);
    const [selectedTargetFiles, setSelectedTargetFiles] = useState<FileDetail[]>([]); 

    const [activeStep, setActiveStep] = useState(0);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [userQuery, setUserQuery] = useState<string>("");
    const [result, setResult] = useState<AuditResult>({ status: "", output: "" });
    const [assignmentId, setAssignmentId] = useState<string>("");

    const steps = [
        {
            id: 0,
            name: "Upload Reference Data",
            heading: "Upload Reference Data",
            component: <FileSelect
                selectedFiles={selectedReferenceFiles}
                setSelectedFiles={setSelectedReferenceFiles}
                errors={errors}
                setErrors={setErrors}
                instructions="Please select <strong>Reference Data</strong> files. Reference Data should include information considered correct or ground truth."
            />,
        }, 
        {
            id: 1,
            name: "Upload Target Data",
            heading: "Upload Target Data",
            component: <FileSelect
                selectedFiles={selectedTargetFiles}
                setSelectedFiles={setSelectedTargetFiles}
                errors={errors}
                setErrors={setErrors}
                instructions="Please select <strong>Target Data</strong> files. Target Data should include information you want to verify."
            />,
        },
        {
            id: 2,
            name: "Query Input",
            heading: "Query Input",
            component: <QueryInput />,
        },
        {
            id: 3,
            name: "SOP Review",
            heading: "SOP Review",
            component: <ReviewPage 
                userQuery={userQuery}
                selectedReferenceFiles={selectedReferenceFiles}
                selectedTargetFiles={selectedTargetFiles}
            />,
        },
        {
            id: 4,
            name: "Results",
            heading: "Results",
            component: <ResultPage/>,
        }
    ]

    const isReviewStep = activeStep === steps.length - 2;

    const _validateFileSelection = (files: FileDetail[]) => {
        if (files.length === 0) {
            setErrors([...errors, { message: "Please select the files." }]);
            return false;
        }

        // file to extract is required
        // disable since it is not required so far
        // for (let i = 0; i < files.length; i++) {
        //     if (files[i].fileSummary === "") {
        //         setErrors([...errors, { message: "File summary is required." }]);
        //         return false;
        //     }
        // }

        return true;
    }

    const _handleSubmit = (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        // create an assignment object
        const assignment = {
            "prompt": userQuery,
            "assignedTo": "auditor",
            "data": {
                "reference_data": selectedReferenceFiles,
                "target_data": selectedTargetFiles
            },
            "status": "pending",
            "created_at": new Date().toISOString(),
            "updated_at": new Date().toISOString()
        }

        createAssignment(assignment).then((response) => {
            console.log(response);
            // if only request is successful, set the assignmentId and move to the result page
            // where the user can see the progress of the verification via websocket
            if (response.status === 201) {
                setAssignmentId(response.data.assignment_id)
                setActiveStep((activeStep) => activeStep + 1);

                // hide the next and back button
                setIsSubmitting(true);
            }
        }).catch((error) => {
            console.log(error);
            // if the request is not successful, set the isSubmitting to false
            setIsSubmitting(false);
        })
    }

    const _handleNext = (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        
        if (!isDevMode) {
            if (activeStep === 0) {
                if (!_validateFileSelection(selectedReferenceFiles)) {
                    return;
                }
            } else if (activeStep === 1) {
                if (!_validateFileSelection(selectedTargetFiles)) {
                    return;
                }
            }
        }

        setActiveStep((activeStep) => activeStep + 1);
    };

    const _handleBack = (e: { preventDefault: () => void }) => {
        e.preventDefault();

        if (!isDevMode) {
            if (activeStep === 0) {
                return;
            } else if (activeStep === 1) {
                if (!_validateFileSelection(selectedReferenceFiles)) {
                    return;
                }
            }
        }

        setActiveStep((activeStep) => activeStep - 1);
    };

    return (
        <DataVerificationContext.Provider
            value={{
                assignmentId,
                setAssignmentId,
                errors,
                setErrors,
                selectedReferenceFiles,
                setSelectedReferenceFiles,
                selectedTargetFiles,
                setSelectedTargetFiles,
                userQuery,
                setUserQuery,
                result,
                setResult,
            }}>
            {/* <Grid item style={{ display: "flex", justifyContent: "center"}}>
                <Typography variant="h5" gutterBottom>
                    {steps[activeStep].heading}
                </Typography>
            </Grid> */}
            {/* Snackbar section */}
            <Snackbar
                open={errors.length > 0}
                autoHideDuration={6000}
                onClose={() => setErrors([])}
            >
                <Alert
                    severity="error"
                    onClose={() => setErrors([])}
                >
                    {errors.map((error, index) => (
                        <div key={index}>{error?.message}</div>
                    ))}
                </Alert>
            </Snackbar>
            {/* Stepper section */}
            <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((step, name) => (
                    <Step key={step.id}>
                        <StepLabel>
                            {/* {step.name} */}
                            <Typography variant="subtitle1">{step.name}</Typography>
                        </StepLabel>
                    </Step>
                ))}
            </Stepper>
            {/* Form section */}
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    height: "100%",
                    mt: 10,
                }}
            >
                {steps[activeStep].component}
                <Box
                    sx={{
                        display: isSubmitting && !isDevMode ? "none" : "flex", 
                        position: "fixed",
                        bottom: "calc(10px + 1vh)",
                    }}
                >
                    <Button disabled={activeStep === 0} onClick={_handleBack}>
                        Back
                    </Button>
                    <Button onClick={isReviewStep ? _handleSubmit : _handleNext}
                        variant="contained" 
                        color="primary"
                        sx={{
                            ml: 2, // margin-left: 2 (spacing)
                            padding: '0.5rem 1.5rem',
                            fontWeight: 'bold',
                            backgroundColor: 'primary.main',
                            '&:hover': {
                                backgroundColor: 'primary.dark',
                            },
                            boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)'
                        }}
                    >
                        {isReviewStep ? "Submit" : "Next"}
                    </Button>
                </Box>
        </Box>
        </DataVerificationContext.Provider>
    );
};

export default VerificationPage;




