// src/routes/index.tsx
import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import routes from './config';
import ProtectedRoute from '../components/ProtectedRoute';

const MainRoutes = () => {
    return (
        <Routes>
            {routes.map((route, index) => {
                const isAuthenticated = localStorage.getItem('authenticated') === 'true';
                console.log('isAuthenticated:', isAuthenticated);

                return (
                    <Route
                        key={index}
                        path={route.path}
                        element={
                            isAuthenticated ? (
                                <route.component />
                            ) : (
                                route.path === "/login" ? (
                                    <route.component />
                                ) : (
                                    <ProtectedRoute element={<route.component />} />
                                )
                            )
                        }
                    />
                );
            })}
        </Routes>
    );
};

export default MainRoutes;
