import React, { useEffect, useState, useMemo } from 'react';
import FileUpload from '../../components/Files/FileUpload';
import { Card, CardHeader, CardActions, CardContent, useTheme } from '@mui/material';
import { IntegrationContainer } from './style';


const LocalUploadCard = () => {
    const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
    return (
        <Card>
            <CardHeader title="Local Upload" />
            <CardContent>
                <FileUpload selectedFiles={selectedFiles} setSelectedFiles={setSelectedFiles}/>
            </CardContent>
        </Card>
    )
}

const Integration = (
) => {
    const theme = useTheme();

    return (
        <IntegrationContainer theme={theme}>
            <LocalUploadCard />
        </IntegrationContainer>
    );
}

export default Integration;