// page to review and submit the documents and user query for verification

import React, { useContext, useEffect, useState } from 'react';
import { Box, Typography, Select, MenuItem, SelectChangeEvent } from '@mui/material';
import { FileDetail } from '../../../types/Files';
import { useNavigate } from 'react-router-dom';
import { Visibility } from '@mui/icons-material';
import FilesList from '../../../components/Files/FilesList';


const ReviewPage = ({
    userQuery,
    selectedReferenceFiles,
    selectedTargetFiles,
}: {
    userQuery: string,
    selectedReferenceFiles: Array<FileDetail>,
    selectedTargetFiles: Array<FileDetail>
}) => {   

    const [sopReview, setSopReview] = useState('MDM Audit Report');

    const handleSopReviewChange = (event: SelectChangeEvent<string>) => {
        setSopReview(event.target.value);
    };
    
    return (
        <Box sx={{ padding: '1rem'}}>

            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '1rem' }}>
                <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', color: 'primary.main' }}>
                    SOP Review:
                </Typography>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: '1rem' }}>
                <Select
                    value={sopReview}
                    onChange={handleSopReviewChange}
                    displayEmpty
                    inputProps={{ 'aria-label': 'SOP Review' }}
                    sx={{ minWidth: '200px' }}
                >
                    <MenuItem value="MDM Audit Report">MDM Audit Report</MenuItem>
                    <MenuItem value="DAMA-DMBOK Report" disabled>DAMA-DMBOK Report</MenuItem>
                    <MenuItem value="ISO 8000 Report" disabled>ISO 8000 Report</MenuItem>
                </Select>
            </Box>


            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                <Box>
                    <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', color: 'primary.main' }}>
                        Your Query:
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        {userQuery}
                    </Typography>
                </Box>
                
                <Box sx={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}>
                    <Box sx={{ flex: 1 }}>
                        <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 'bold', color: 'primary.main' }}>
                            Reference Files
                        </Typography>
                        <FilesList
                            selectedFiles={selectedReferenceFiles}
                            setSelectedFiles={() => { }}
                            errors={[]}
                            setErrors={() => { }}
                            canAddFiles={false}
                            canDeleteFiles={false}
                        />
                    </Box>

                    <Box sx={{ flex: 1 }}>
                        <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 'bold', color: 'primary.main' }}>
                            Target Files
                        </Typography>
                        <FilesList
                            selectedFiles={selectedTargetFiles}
                            setSelectedFiles={() => { }}
                            errors={[]}
                            setErrors={() => { }}
                            canAddFiles={false}
                            canDeleteFiles={false} 
                        />
                    </Box>
                </Box>
            </Box>

        </Box>
    )
}

export default ReviewPage;