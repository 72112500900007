import React, { useMemo } from 'react';
import { DataGrid, GridColDef, GridRowsProp } from "@mui/x-data-grid";

export const Table2DArray = ({ data }: { data: Array<Array<string>> }) => {

    const parseData = (data: Array<Array<string>>) => {
        if (data.length === 0) {
            return [];
        }
        const column_names = data[0];
        const row_records = data.slice(1);
    
        const headers: GridColDef[] = column_names.map((header, index) => ({
            field: `col${index}`,
            headerName: header,
            width: 150,
        }));

        const rows: GridRowsProp = row_records.map((row, rowIndex) => {
            const rowData: { [key: string]: string } = {};
            row.forEach((cell, cellIndex) => {
                rowData[`col${cellIndex}`] = cell;
            });
            return { id: rowIndex, ...rowData };
        });
        
        return [headers, rows]
    }

    const [headers, rows] = useMemo(() => parseData(data), [data]);

    return (
        <div style={{ height: 400, width: '100%', marginTop: '10px' }}>
            <DataGrid rows={rows} columns={headers as GridColDef[]}/>
        </div>
    );
};