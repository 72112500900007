// a page to show the result of the verification

import React, { useEffect, useState, useContext, useRef } from 'react';
import { Box, Typography, LinearProgress, CircularProgress, Button, Link} from '@mui/material';
import { DataVerificationContext } from '../../../context/DataVerificationContext';
import Markdown from 'react-markdown';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { DataGrid } from '@mui/x-data-grid';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

const ResultPage = () => {

    const { assignmentId, result, setResult } = useContext(DataVerificationContext);
    const [progress, setProgress] = useState(0);
    const [timeout, setTimeoutFlag] = useState(false);

    useEffect(() => {
        if (!assignmentId) {
            return;
        }

        setResult({ status: "created", output: "" });
        setProgress(10);
        
        const ws = new WebSocket(`${process.env.REACT_APP_WEBSOCKET_URL}/assignments/ws/${assignmentId}`);
        
        let pingInterval: NodeJS.Timeout | undefined;
        const timeoutId = setTimeout(() => {
            setTimeoutFlag(true);
            ws.close();
        }, 300000); // 5 minutes

        ws.onopen = () => {
            console.log('WebSocket connected');

            // Start sending pings every 10 seconds
            pingInterval = setInterval(() => {
                if (ws.readyState === WebSocket.OPEN) {
                    ws.send(JSON.stringify({ type: 'ping' }));
                    console.log('Ping sent');
                }
            }, 10000);
        }

        ws.onmessage = (event) => {
            const data = JSON.parse(event.data);
            console.log('Received:', data);
            if (data.status === "assigned") {
                setResult({ "status": data.status, "output": "The task has been assignend to a worker. Please wait for the results." })
                setProgress(20);
            } else if (data.status === 'in_progress') {
                setResult({ "status": data.status, "output": "The task is in progress. Please wait for the results." })
                setProgress(50);
            } else if (data.status === 'completed') {
                clearTimeout(timeoutId);
                clearInterval(pingInterval);
                setResult({ "status": data.status, "output": data.output })
                setProgress(100);
            } else if (data.status === 'error') {
                clearTimeout(timeoutId);
                clearInterval(pingInterval);
                setResult({ "status": data.status, "output": data.output })
                setProgress(100);
            }
        }

        ws.onerror = (event) => {
            console.error('WebSocket error:', event);
        }

        ws.onclose = (event) => {
            clearInterval(pingInterval);
            if (!event.wasClean) {
                console.error('WebSocket connection closed unexpectedly:', event);
            }
        }

        return () => {
            clearTimeout(timeoutId);
            ws.close();
            setProgress(0);
        }
    }, [assignmentId])

    // using useRef to store the timeout ID to ensure it is accessible across renders
    // const timeoutIdRef = useRef<NodeJS.Timeout | null>(null); 

    // const handleWebSocketMessage = (data: any) => {
    //     console.log('Received:', data);
    //     console.log("Output: ", data.output.split('\n').map((line: string) => line));

    //     if (data.status === "assigned") {
    //         setResult({ "status": data.status, "output": "The task has been assigned to an AI agent. Please wait for the results." });
    //         setProgress(20);
    //     } else if (data.status === 'in_progress') {
    //         setResult({ "status": data.status, "output": "The task is in progress. Please wait for the results." });
    //         setProgress(50);
    //     } else if (data.status === 'completed' || data.status === 'error') {
    //         // clearing timeout as the task is completed or encountered an error
    //         if (timeoutIdRef.current) {
    //             clearTimeout(timeoutIdRef.current);
    //             timeoutIdRef.current = null; // Clear the ref after cleaning the timeout
    //         }
    //         setResult({ "status": data.status, "output": data.output });
    //         setProgress(data.status === 'completed' ? 100 : 0);
    //     }
    // };

    // const { isConnected, connect, disconnect } = useWebSocket(
    //     websocketUrl,
    //     {
    //         onMessage: handleWebSocketMessage,
    //         onError: (error) => {
    //             console.error('WebSocket error:', error);
    //             setResult({ "status": "error", "output": "An error occurred while processing the task." });
    //             setProgress(0);
    //         },
    //         onClose: (event) => {
    //             console.log('WebSocket connection closed:', event);
    //             // Optionally check if the closure was expected or not
    //             if (!event.wasClean) {
    //                 setResult({ "status": "error", "output": "The task has been cancled." });
    //                 setProgress(0);
    //             }
    //         },
    //     }
    // ) 

    // useEffect(() => {
    //     if (!assignmentId) {
    //         console.log("Assignment ID not found");
    //         return;
    //     }

    //     connect();

    //     timeoutIdRef.current = setTimeout(() => {
    //         setTimeoutFlag(true);
    //         disconnect();
    //     }, 300000); // 5 minutes

    //     return () => {
    //         if (timeoutIdRef.current) {
    //             clearTimeout(timeoutIdRef.current);
    //         }
    //         disconnect();
    //         setProgress(0);
    //     }
    // }, [assignmentId, connect, disconnect]);

    // const startPolling = (user_id: string, assignment_id: string) => {
    //     const interval = setInterval(async () => {
    //         try {
    //             const response = await axios.get('/api/assignments/1/status'); // Replace with dynamic assignment ID
    //             const message = response.data;
    //             setMessages((prevMessages) => [...prevMessages, message]);
    //         } catch (error) {
    //             console.error('Polling error:', error);
    //             clearInterval(interval);
    //         }
    //     }, 5000); // Poll every 5 seconds
    // };

    const renderMarkdownTable = (props: any) => {
        const { children } = props;
        const rows = children[0].props.children;
        const columns = rows[0].props.children.map((column: any) => {
            return {
                field: column.props.children,
                headerName: column.props.children,
                width: 150,
            }
        });
        const data = rows.slice(1).map((row: any) => {
            const rowData = row.props.children.map((cell: any) => {
                return cell.props.children;
            });
            return {
                id: rowData[0],
                ...rowData.slice(1),
            }
        });
        return (
            <Box style={{ height: 400, width: '100%' }}>
                <DataGrid
                    rows={data}
                    columns={columns}
                    pageSizeOptions={[5, 10, 20]}
                />
            </Box>
        );
    }

    // const handleStartOver = () => {
    //     // Reset necessary states and navigate to the starting step
    //     setResult({ status: "created", output: "" });
    //     setProgress(0);
    //     setTimeoutFlag(false);
    //     navigate('/verification'); // Update this path to the correct step 1 route
    // };

    const handleDownloadPDF = () => {
        const input = document.getElementById('result-content');
        html2canvas(input as HTMLElement).then((canvas) => {
            const pdfData = canvas.toDataURL('image/jpeg', 1.0);
            const pdf = new jsPDF('p', 'mm', 'a4');
            pdf.addImage(pdfData, 'JPEG', 0, 0, 210, 297);
            pdf.save('verification-result.pdf');
        });
    }

    return (
        <Box sx={{ maxWidth: '1000px', mx: 'auto', p: 2 }}>
            <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold', color: 'primary.main' }}>
                Verification Results
            </Typography>

            <Box sx={{
                width: '100%',
                display: result.status === "completed" ? 'none' : 'block'
            }}>
                <LinearProgress variant="determinate" value={progress} />
            </Box>

            <Box
                id="result-content"
                sx={{
                    mt: 2,
                    backgroundColor: '#f9f9f9',
                    p: 2,
                    borderRadius: '8px',
                    boxShadow: 1,
                }}>
                <Box sx={{ lineHeight: '1.6', '& p': { marginBottom: '16px' }, '& ul': { marginBottom: '16px' } }}>
                    <ReactMarkdown
                        children={timeout ? 'The task has timed out. Please try again later. \n\n Tip: A clear and detailed query can help achieve fast reasoning.' : result.output}
                        remarkPlugins={[remarkGfm]}
                        components={{
                            table: renderMarkdownTable
                        }}
                    />
                     {(result.status === 'assigned' || result.status === 'in_progress') && !timeout && (
                        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                            <CircularProgress />
                        </Box>
                    )}
                </Box>
            </Box>

            {(result.status === 'completed' || result.status === 'error' || timeout) && (
                <Box sx={{ mt: 2 }}>
                    <Button variant="contained" color="primary" onClick={handleDownloadPDF}>
                        Download as PDF
                    </Button>
                    <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
                        <Link href="/" color="inherit">
                            Back to Dashboard
                        </Link>
                    </Typography>
                </Box>
            )}
        </Box>
    );
};

export default ResultPage;

