//src/views/Login/index.tsx
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TextField, Button, Container, Typography, Box } from '@mui/material';

const Login: React.FC = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    const handleLogin = (e: React.FormEvent) => {
        e.preventDefault();

        const envUsername = process.env.REACT_APP_USERNAME;
        const envPassword = process.env.REACT_APP_PASSWORD;

        if (username === envUsername && password === envPassword) {
            localStorage.setItem('authenticated', 'true');
            // navigate('/');
            window.location.href = '/';
            console.log('Login successful, navigating to home page...');
        } else {
            alert('Invalid credentials');
        }
    };

    return (
        <Container className="login-container">
            <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                minHeight="100vh"
            >
                <Typography variant="h4">Pilot User Login</Typography>
                <form onSubmit={handleLogin} style={{ width: '25%' }}>
                    <TextField
                        label="Username"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                    <TextField
                        label="Password"
                        type="password"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        fullWidth
                        style={{ marginTop: '1rem' }}
                    >
                        Login
                    </Button>
                </form>
            </Box>
        </Container>
    );
};

export default Login;