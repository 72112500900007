import axios from "axios";

console.log("react api url: ", process.env.REACT_APP_API_URL)

export const axiosInstance = axios.create({
	baseURL: process.env.REACT_APP_API_URL,
	timeout: 10000,
	headers: {
		"Content-Type": "application/json",
	},
});
