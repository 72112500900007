import React from "react";
import { Toolbar, Typography, Menu, MenuItem, Link, Tooltip, IconButton, Avatar, Box } from "@mui/material";
import { useLayout } from "../../context/BaseLayoutContext";
import StyledAppBar from "./style";

const menus = [
	{
		name: "Home",
		path: "/"
	},
	{
		name: "Profile",
		path: "/profile"
	},
	{
		name: "Setting",
		path: "/setting"
	}
]

const Header = () => {
	const [anchorElUserMenu, setAnchorElUserMenu] = React.useState<null | HTMLElement>(null);

	const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorElUserMenu(event.currentTarget);
	}

	const handleCloseUserMenu = () => {
		setAnchorElUserMenu(null);
	}

	return (
		<StyledAppBar>
			<Toolbar>
				{/* <Typography
					variant="h5"
					noWrap
					component="div"
					sx={{
						margin: 'auto',
						backgroundImage: 'linear-gradient(to right, violet, pink, skyblue)',
						WebkitBackgroundClip: 'text',
						WebkitTextFillColor: 'transparent',
						fontWeight: 'bold'
					}}
				>
					Daicus
				</Typography> */}
				
				<Box sx={{ flexGrow: 0 }} >
					<Tooltip title="Open settings">
						<IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
							<Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
						</IconButton>
					</Tooltip>
					<Menu
						sx={{ mt: '45px'}}
						id="menu-appbar"
						anchorEl={anchorElUserMenu}
						anchorOrigin={{
							vertical: 'top',
							horizontal: 'right',
						}}
						keepMounted
						transformOrigin={{
							vertical: 'top',
							horizontal: 'right',
						}}
						open={Boolean(anchorElUserMenu)}
						onClose={handleCloseUserMenu}
						>
						{menus.map((menu_item) => (
							<MenuItem key={menu_item.name} onClick={handleCloseUserMenu}>
								<Link href={menu_item.path} color="inherit">
									{menu_item.name}
								</Link>
							</MenuItem>
						))}
					</Menu>
				</Box>
			</Toolbar>
		</StyledAppBar>
	);
};

export default Header;
