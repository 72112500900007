import React, { ChangeEvent, useState, useRef, useEffect, useMemo } from "react";
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardMedia,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormHelperText,
    FormControl,
    FormLabel,
    FormGroup,
    FormControlLabel,
    IconButton,
    Slide,
    TextField,
    Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import Visibility from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import { TransitionProps } from '@mui/material/transitions';
import FilePresentIcon from '@mui/icons-material/FilePresent';

import * as XLSX from "xlsx";
import { ErrorMessage } from "../../context/DataVerificationContext";
import { FileDetail } from "../../types/Files";
import { AddBoxOutlined, FemaleSharp } from "@mui/icons-material";
import { Table2DArray } from "../Tables";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const FilesList = ({
    selectedFiles,
    setSelectedFiles,
    errors,
    setErrors,
    canAddFiles,
    canDeleteFiles,
}: {
        selectedFiles: FileDetail[]
        setSelectedFiles: React.Dispatch<FileDetail[]>
        errors: Array<ErrorMessage>,
        setErrors: React.Dispatch<Array<ErrorMessage>>,
        canAddFiles: boolean,
        canDeleteFiles: boolean,
}) => {
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogContent, setDialogContent] = useState<JSX.Element | null>(null);
    const inputFileRef = useRef<HTMLInputElement | null>(null);

    useEffect(() => {

    }, [selectedFiles]);

    const handleAddNewFile = async (event: ChangeEvent<HTMLInputElement>) => {
        const newFile = event.target.files ? event.target.files[0] : null;
        // no duplicate files allowed
        if (newFile) {
            if (!selectedFiles.some((fileDetail) => fileDetail.fileName === newFile.name)) {
                await readFileContent(newFile).then((fileContent) => {
                    // if (newFile?.type === "application/pdf" || newFile?.type === "text/csv" || /\.(csv|xlsx|xls)$/.test(newFile.name)) {
                    if (["application/pdf", "application/vnd.openxmlformats-officedocument.wordprocessingml.document", "application/vnd.openxmlformats-officedocument.presentationml.presentation", "text/csv"].includes(newFile?.type) || /\.(csv|xlsx|xls|docx|pptx)$/.test(newFile.name)) {
                        const newFileDetail: FileDetail = {
                            fileName: newFile.name,
                            fileType: newFile.type,
                            fileSummary: "",
                            fileContent: fileContent as string | Array<Array<string>>,
                            isFinancialData: false,
                            isAccountingData: false,
                            isContractData: false,
                        }
                        setSelectedFiles([...selectedFiles, newFileDetail]);
                    }
                });
            }
        }
    };

    const handleOpenDialog = (fileDetail: FileDetail) => {
        // show the file content in a modal
        setDialogOpen(true);

        if (fileDetail.fileType === "application/pdf") {
            setDialogContent(
                <iframe src={fileDetail.fileContent as string} width="100%" height="600px" title="file-preview" />
            )
        } else if (fileDetail.fileType === "text/csv" || /\.(csv|xlsx|xls)$/.test(fileDetail.fileName)) {
            setDialogContent(<Table2DArray data={fileDetail.fileContent as Array<Array<string>>} />);
        } else if (fileDetail.fileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" || fileDetail.fileType === "application/vnd.openxmlformats-officedocument.presentationml.presentation" || /\.(docx|pptx)$/.test(fileDetail.fileName)) {
            setDialogContent(
                <iframe src={fileDetail.fileContent as string} width="100%" height="600px" title="file-preview" />
            );
        }
    }

    const handleCloseDialog = () => {
        setDialogOpen(false);
    };

    // return the file content
    const readFileContent = async (file: File): Promise<string | ArrayBuffer | Array<Array<string>> | null> => {
        return new Promise((resolve, reject) => {

            // read the file content
            const reader = new FileReader();

            if (file.type === "application/pdf") {
                reader.readAsDataURL(file);
            } else if (file.type === "test/csv" || /\.(csv|xlsx|xls)$/.test(file.name)) {
                reader.readAsArrayBuffer(file);
            } else if (file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" || file.type === "application/vnd.openxmlformats-officedocument.presentationml.presentation" || /\.(docx|pptx)$/.test(file.name)) {
                reader.readAsDataURL(file);
            }

            reader.onloadend = () => {
                if (file.type === "text/csv" || /\.(csv|xlsx|xls)$/.test(file.name)) {
                    const workbook = XLSX.read(reader.result, { type: 'buffer' });
                    const sheetName = workbook.SheetNames[0];
                    const sheet = workbook.Sheets[sheetName];
                    const tabularData: Array<Array<string>> = XLSX.utils.sheet_to_json(sheet, { header: 1 });
                    resolve(tabularData);
                } else {
                    resolve(reader.result);
                }
            };

            reader.onerror = () => {
                reject("Error reading the file");
            }
        });
    }

    const handleDeleteFile = (fileDetail: FileDetail) => {
        const updatedFiles = selectedFiles.filter((uploadedFile) => uploadedFile !== fileDetail);
        setSelectedFiles(updatedFiles);
    };


    const _renderSelectedFiles = () => {
        function getSnapshotOfFile(file: File): string | undefined {
            // get the snapshot of the file
            if (file.type === "application/pdf") {
                // console.log("fileurl: ", URL.createObjectURL(file))
                return URL.createObjectURL(file);
            }
        }

        function handleSummaryChange(fileDetail: FileDetail, value: string): void {
            // handle the file summary change
            fileDetail.fileSummary = value;
        }

        function handleIsFinancialData(fileDetail: FileDetail, event: React.ChangeEvent<HTMLInputElement>) {
            // Update the selected fileDetail in the selected files
            const updatedFiles = selectedFiles.map((f) => {
                if (f === fileDetail) {
                    f.isFinancialData = event.target.checked;
                }
                return f;
            });

            setSelectedFiles(updatedFiles);
        }

        function handleIsAccountingData(fileDetail: FileDetail, event: React.ChangeEvent<HTMLInputElement>) {
            // Update the selected fileDetail in the selected files
            const updatedFiles = selectedFiles.map((f) => {
                if (f === fileDetail) {
                    f.isAccountingData = event.target.checked;
                }
                return f;
            });

            setSelectedFiles(updatedFiles);
        }

        function handleIsContractData(fileDetail: FileDetail, event: React.ChangeEvent<HTMLInputElement>) {
            // Update the selected fileDetail in the selected files
            const updatedFiles = selectedFiles.map((f) => {
                if (f === fileDetail) {
                    f.isContractData = event.target.checked;
                }
                return f;
            });

            setSelectedFiles(updatedFiles);
        }

        return (
            <>
                {selectedFiles.map((fileDetail, index) => (
                    <Card key={index} sx={{
                        display: "flex",
                        alignItems: "center",
                        mb: 2, // margin-bottom for spacing between cards
                        width: "100%",
                        position: "relative",
                        '&:hover .visibilityIcon': {
                            visibility: 'visible',
                        },
                        '&:hover .delete-file-icon': {
                            visibility: 'visible',
                        }
                    }}>
                        {/* Assuming you have a way to generate a snapshot of the file */}
                        <Box
                            sx={{
                                position: 'relative',
                                width: 151,
                                height: 'auto', // or set a fixed height
                                '&:hover .visibilityIcon': {
                                    visibility: 'visible',
                                },
                                textWrap: "wrap"
                            }}
                        >
                            {/* <CardMedia
                                component="img"
                                sx={{
                                    width: '100%', 
                                    height: '100%'
                                }}
                                //image={"https://img.icons8.com/?size=100&id=iBeRV9QTCIH8&format=png&color=000000"} // TODO: implement the getSnapshotOfFile function
                                image={"https://img.icons8.com/?size=100&id=92819&format=png&color=000000"}
                                alt="Snapshot of file"
                            /> */}
                            <Box
                                sx={{ 
                                    display: 'flex', 
                                    alignItems: 'center', 
                                    justifyContent: 'center', 
                                    width: '100%', 
                                    height: '100%' 
                                }}
                            >
                                <CardMedia
                                    component="img"
                                    sx={{ width: '50%', height: '50%' }}
                                    image={"https://img.icons8.com/?size=100&id=iBeRV9QTCIH8&format=png&color=000000"}
                                    alt="Snapshot of file"
                                />
                            </Box>
                            <IconButton
                                aria-label="view"
                                sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    visibility: 'hidden', // Hide icon initially
                                }}
                                className="visibilityIcon"
                                onClick={() => handleOpenDialog(fileDetail)}
                            >
                                <Visibility />
                            </IconButton>
                            <Typography 
                                variant="body1" 
                                gutterBottom
                                sx={{ 
                                    textAlign: 'center', // Centers the text
                                    width: '100%', // Ensures the text takes full width
                                    fontSize: '0.85rem',
                                    mt: 2
                                }}
                            >
                                {fileDetail.fileName}
                            </Typography>
                        </Box>
                        <Box
                            sx={{ flexGrow: 1 }}
                        >
                            <CardContent>
                                <FormGroup>
                                    <FormControl>
                                        <FormLabel>What to extract? <br />
                                        (Optional, recommended for better performance)</FormLabel>
                                        <TextField
                                            placeholder="e.g. Extract the name, address, and phone number of the customers."
                                            fullWidth
                                            variant="outlined"
                                            size="small"
                                            multiline
                                            rows={4}
                                            required
                                            defaultValue={fileDetail.fileSummary}
                                            onChange={(event) => handleSummaryChange(fileDetail, event.target.value)} // You need to implement this function
                                        />
                                    </FormControl>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={fileDetail.isFinancialData}
                                                onChange={(event) => handleIsFinancialData(fileDetail, event)}
                                                name="financial-data-indicator"
                                            />
                                        }
                                        label={
                                            <Typography component="span">
                                                <strong>Custom Feature</strong>: Financial Data Structure
                                            </Typography>
                                        }
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={fileDetail.isAccountingData}
                                                onChange={(event) => handleIsAccountingData(fileDetail, event)}
                                                name="accounting-data-indicator"
                                            />
                                        }
                                        label={
                                            <Typography component="span">
                                                <strong>Custom Feature</strong>: Accounting Data Structure
                                            </Typography>
                                        }
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={fileDetail.isContractData}
                                                onChange={(event) => handleIsContractData(fileDetail, event)}
                                                name="contract-data-indicator"
                                            />
                                        }
                                        label={
                                            <Typography component="span">
                                                <strong>Custom Feature</strong>: Contract Data Structure
                                            </Typography>
                                        }
                                    />
                                </FormGroup>
                            </CardContent>
                        </Box>
                        <CardActions>
                            <Dialog
                                fullScreen
                                open={dialogOpen}
                                onClose={handleCloseDialog}
                                maxWidth="md"
                                TransitionComponent={Transition}
                            >
                                <DialogTitle>{fileDetail.fileName}</DialogTitle>
                                <DialogContent>
                                    {dialogContent}
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={handleCloseDialog}>Close</Button>
                                </DialogActions>
                            </Dialog>
                            {canDeleteFiles &&
                                <IconButton
                                    aria-label="delete"
                                    sx={{
                                        position: "absolute",
                                        top: 0,
                                        right: 0,
                                        visibility: "hidden", // Hide icon initially
                                    }}
                                    className="delete-file-icon"
                                    onClick={() => handleDeleteFile(fileDetail)}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            }
                            
                        </CardActions>
                    </Card>
                ))}
            </>
        );
    };

    return (
        <Box
            component="div"
            sx={{
                position: "relative",
                width: "75%",
                border: '2px dashed #ccc',
                borderRadius: "4px",
                mb: 2,
            }}
        >
            <Box sx={{
                position: "relative",
                overflow: "auto",
                height: "40vh",
                p: 2,
            }}>
                {_renderSelectedFiles()}
            </Box>
            
            {canAddFiles && 
                <IconButton
                component="label"
                aria-label="select file"
                htmlFor="file-select"
                color="primary"
                size="large"
                sx={{
                    position: "absolute",
                    bottom: 1,
                    right: 1,
                    zIndex: 1000,
                    transformOrigin: "center",
                    transition: "transform 0.2s ease-in-out",
                    '&:hover': {
                        transform: "scale(1.3)",
                    },
                }}
            >
                <AddBoxOutlined fontSize="inherit" />
                <input
                    type="file"
                    hidden
                    accept=".csv, .xlsx, .xls, .pdf, .docx, .pptx"
                    id="file-select"
                    onChange={handleAddNewFile}
                    ref={inputFileRef}
                />
            </IconButton>
            }
        </Box>
    );
};

export default FilesList;
