// page where the user can upload the reference data

import React, {useContext, useState} from 'react';
import { Typography, Box } from '@mui/material';
import FileUpload from "../../../components/Files/FileUpload";
import { ErrorMessage } from "../../../context/DataVerificationContext";
import { FileDetail } from "../../../types/Files";
import FilesList from '../../../components/Files/FilesList';

const FileSelect = ({
    selectedFiles,
    setSelectedFiles,
    errors,
    setErrors,
    instructions
}: {
    selectedFiles: Array<FileDetail>,
    setSelectedFiles: React.Dispatch<Array<FileDetail>>,
    errors: Array<ErrorMessage>,
    setErrors: React.Dispatch<Array<ErrorMessage>>,
    instructions: string
}) => {
    
    return (
        <>
            <Box component="div">
                <Typography variant="body1" gutterBottom>
                    {/* {instructions} */}
                    <p dangerouslySetInnerHTML={{ __html: instructions }} />
                </Typography>
            </Box>
            <FilesList
                selectedFiles={selectedFiles}
                setSelectedFiles={setSelectedFiles}
                errors={errors}
                setErrors={setErrors}
                canAddFiles={true}
                canDeleteFiles={true}
            />
        </>
    );
};

export default FileSelect;