import React, { useContext } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { Textarea } from '../../../components/Textarea';
import { DataVerificationContext } from '../../../context/DataVerificationContext';

const QueryInput = () => {

    const { userQuery, setUserQuery } = useContext(DataVerificationContext);

    {/* on query suggestion click, fill the query input with the suggestion */ }
    const handleSuggestionClick = (event: React.MouseEvent) => {
        const suggestion = event.currentTarget.textContent as string;
        setUserQuery(suggestion);
    }

    const content = `
    The maximum allowed weekly work time in compliance.pdf is 40 hours. However, workers W002, W004, and W005 in work_hours.xlsx have weekly work hours exceeding this limit.
    | Worker ID | Weekly Work Hours | Requirement Met |
    |-----------|-------------------|-----------------|
    | W001      | 40                | Yes             |
    | W002      | 42                | No              |
    | W003      | 38                | Yes             |
    | W004      | 45                | No              |
    | W005      | 41                | No              |
    `;
    
    
    return (
        <
        >
            <Typography variant="body1" gutterBottom>
                Please enter your query below:
            </Typography>
        
            <Textarea
                aria-label='Query input'
                maxRows={6}
                minRows={3}
                onChange={(e) => setUserQuery(e.target.value)} value={userQuery}
                placeholder="Enter your query here"
                sx={{ width: '500px', height: '150px' }} 
            />

            {/* query suggestions */}
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                gap: '8px',
                width: '500px'
            }}>
                {/* TODO: When button on clicked, filled the user input area with the value
                <Button variant="outlined" color="secondary" size="small" onClick={handleSuggestionClick}>
                    Query Suggestion 1
                </Button>
                <Button variant="outlined" color="secondary" size="small">
                    Query Suggestion 2
                </Button> */}
                <Typography variant="body2" gutterBottom sx={{ color: '#616161' , mt: 2}}>
                    <strong>A good example:</strong> <br />
                    Please check whether the <strong>total assets</strong> reported in <u>Q4 Report</u> match the <strong>total assets</strong> numbers shown on the <u>balance sheet</u>.
                    <br /><br />
                    <strong>Tips:</strong>
                    <ul>
                        <li>Clearly reference the source and target files.</li>
                        <li>Provide the specific metrics or information to be verified.</li>
                    </ul>
                </Typography>
            </Box>
        </>
    );
};

export default QueryInput;